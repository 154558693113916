import React from 'react';
import {graphql, Link} from 'gatsby';
// import MDXRenderer from "gatsby-mdx/mdx-renderer";
import TitlePage from '../components/TitlePage';
import SEO from '../components/seo';

import * as S from '../components/Content/styled';
import Section from "../components/Section";
import Heading from "../components/Heading";
import {MDXRenderer} from "gatsby-plugin-mdx";
import FacebookIcon from "../assets/icons/ico_facebook.svg";
import LinkedInIcon from "../assets/icons/ico_LinkedIn.svg";
import YoutubeIcon from "../assets/icons/ico_YouTube.svg";

const categories = [
    {
        name: 'Živnosť (Freelance)',
        count: 20,
    },
    {
        name: 'S.R.O. (Limited Liability)',
        count: 12,
    },
    {
        name: 'Non-profits',
        count: 7,
    },
    {
        name: 'Start company',
        count: 5,
    },
    {
        name: 'Slovakia',
        count: 2,
    },
];

const MdxBlockquote = ({ href, ...props }) => (
    <>

    </>
);

const Post = props => {
  const post = props.data.post;

  const featuredPosts = props.data.featuredPosts.edges || [];

  return (
    <>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={post.frontmatter.image}
      />
    <Section className={'py-12 text-center mx-auto'}>
        <Heading type="h2" className={'w-5/6 mx-auto mb-4'}>{post.frontmatter.title}</Heading>
        <div className={"text-ink-60 font-medium"}>
            <span className={"mr-4 text-ink-60"}>{post.frontmatter.category}</span>
            {/*<span>by {post.frontmatter.author}</span>*/}
            <span>by Richard Tomášik</span>
        </div>
    </Section>
    <Section>
        <div className={'grid lg:grid-cols-3 lg:gap-12'}>
            <div className={'col-span-2'}>
                <div className="flex justify-center mb-8">
                    <img src={post.frontmatter.featuredimage} alt="" className="w-full object-contain"/>
                </div>
                <div className="text-ink-60 text-lg post-body mb-8">
                    <MDXRenderer
                        components={{
                            a: MdxBlockquote,
                        }}
                    >
                        {post.body}
                    </MDXRenderer>
                </div>
                <div className="flex">
                    <div className={'flex items-center gap-4'}>
                        <p className={'font-medium text-ink-100'}>Tags:</p>
                        <div className={'py-1.5 px-4 bg-ink-10 rounded text-ink-60'}>Živnosť</div>
                        <div className={'py-1.5 px-4 bg-ink-10 rounded text-ink-60'}>Založenie</div>
                    </div>
                    <div className={'flex space-x-8 flex-1 justify-end'}>
                        <FacebookIcon className={'w-6 h-6'}/>
                        <LinkedInIcon className={'w-6 h-6'}/>
                        <YoutubeIcon className={'w-6 h-6'}/>
                    </div>
                </div>
            </div>
            <div className={''}>
                {/*<div className={''}>Search</div>*/}
                <div className={''}>
                    <Heading type='h5' className={'mb-4 font-semibold'}>Featured posts</Heading>
                    <div className={'space-y-6'}>
                        {featuredPosts.map(({node: post}) => (
                            <div>
                                <div>
                                    <p className={'text-sm text-ink-60 opacity-50 mb-1'}>
                                        {post.frontmatter.date}
                                    </p>
                                    <Link to={'/'} className={'text-ink-60 font-medium'}>{post.frontmatter.title}</Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={'mt-16'}>
                    <Heading type='h5' className={'mb-4 font-semibold'}>Categories</Heading>
                    <div className={'space-y-6'}>
                        {categories.map((category) => (
                            <div className={'flex justify-between items-center'}>
                                <Link to={'/'} className={'text-ink-60 font-medium'}>{category.name}</Link>
                                <p className={'text-sm text-ink-60 opacity-50 mb-1'}>({category.count})</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>

    </Section>
    </>
  );
};

export const query = graphql`
  query Post($locale: String!, $slug: String!) {
    post: mdx(
      fields: { locale: { eq: $locale }, slug: {eq: $slug}}
    ) {
      body
      frontmatter {
        title
        description
        featuredimage
        category
      }
    }
    featuredPosts: allMdx(filter: {fields: {locale: {eq: $locale}}}, sort: {order: ASC, fields: frontmatter___date}) {
    edges {
      node {
        id
        frontmatter {
          title
          date(formatString: "DD MMMM YYYY")
        }
      }
    }
  }
  }
`;

export default Post;
